import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner2.jpg";
import news21Img from "../../images/news/news2_1.jpg";
import news22Img from "../../images/news/news2_2.jpg";
import news26Img from "../../images/news/news2_6.jpg";

import { withTranslation } from "react-i18next";

	
class News2 extends Component {
	componentDidMount() {
		
	}
	
	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News2_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News2_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News2_LinkHome")}</Link>
								</li>
								<li>{t("News2_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("News2_Content_H")}</h1>
											<p>{t("News2_Content_P1")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news21Img} /></p>
											<p style={{textAlign: "center"}}><img alt="" src={news22Img} /></p>
											<p>{t("News2_Content_P2")}</p>
											<p>{t("News2_Content_P3")}</p>
											<p>{t("News2_Content_P4")}</p>
											<p>{t("News2_Content_P5")}</p>
											<p>{t("News2_Content_P6")}</p>
											<p>{t("News2_Content_P7")}</p>
											<p>{t("News2_Content_P8")}</p>
											<p>{t("News2_Content_P9")}</p>
											<p>{t("News2_Content_P10")}</p>
											<p>{t("News2_Content_P11")}</p>
											<p>{t("News2_Content_P12")}</p>
											<p>{t("News2_Content_P13")}</p>
											<p>{t("News2_Content_P14")}</p>
											<p>{t("News2_Content_P15")}</p>
											<p>{t("News2_Content_P16")}</p>
											<p>{t("News2_Content_P17")}</p>
											<p>{t("News2_Content_P18")}</p>
											<p>{t("News2_Content_P19")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news26Img} /></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default News2 = withTranslation()(News2);
