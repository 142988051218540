import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import icon1 from '../../images/icon/icon1.png';
import icon2 from '../../images/icon/icon2.png';
import icon3 from '../../images/icon/icon3.png';

import { withTranslation } from 'react-i18next';


const content = [
	{
		IconImg: icon1,
		Title: "FeatureContent1_Title_1",
		Text: "FeatureContent1_Text_1",
	},
	{
		IconImg: icon2,
		Title: "FeatureContent1_Title_2",
		Text: "FeatureContent1_Text_2",
	},

]
const IconImg = icon3;
const title_vision = "FeatureContent1_Title_3";

const content2 = [
	{
		Title: "FeatureContent1_Title_3_1",
		Text: "FeatureContent1_Text_3_1",
	},
	{
		Title: "FeatureContent1_Title_3_2",
		Text: "FeatureContent1_Text_3_2",
	},
	{
		Title: "FeatureContent1_Title_3_3",
		Text: "FeatureContent1_Text_3_3",
	},
	{
		Title: "FeatureContent1_Title_3_4",
		Text: "FeatureContent1_Text_3_4",
	},
	{
		Title: "FeatureContent1_Title_3_5",
		Text: "FeatureContent1_Text_3_5",
	},
]
const text_title = "FeatureContent1_Text_Title_4";

const content3 = [
	{ Text: "FeatureContent1_Text_4_1" },
	{ Text: "FeatureContent1_Text_4_2" },
	{ Text: "FeatureContent1_Text_4_3" },
	{ Text: "FeatureContent1_Text_4_4" },
	{ Text: "FeatureContent1_Text_4_5" },
];

class FeatureContent1 extends Component {
	componentDidMount() {
		
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div className="row">
					<div className="col-lg-12 col-md-12">
						<div className='container'>
							<div>
								<br/><br />
								<p className='mx-4 text-center '>
									{t("featureContent1_companyDescription")}
								</p>
								<br /><br /><br /><br /><hr />
							</div>
						</div>
						<div className="heading-bx left p-lr45 m-lr30">
							<h3 className="title-head">{t("MissionValue_Title")}</h3>
						</div>

						{/* mission and vision */}
						<div className="container">
							<div className="row">
								{content.map((item, index) => (
									<div className="col-lg-12 col-md-12 col-sm-12 mx-4 mb-3" key={index}>
										<div className="card p-lg-5 m-lg-4 m-sm-2 shadow-lg" style={{ borderRadius: '15px', backgroundColor: '', display: 'flex', flexDirection: 'column' }}>
											<div className='row py-3 px-3'>
												<div className="icon-content col-lg-4 col-md-4 col-sm-12 mb-3 float-left">
													<div className="feature-md float-left">
														<Link to="#" className="icon-cell"><img alt="" src={t(item.IconImg)} /></Link>
													</div>
													<h2 className="float-left">{t(item.Title)}</h2>
												</div>
												<div className='col-lg-8 col-md-8 col-sm-12 float-right'>
													<p>{t(item.Text)}</p>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
							{/* Values */}
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 mx-4 mb-3">
									<div className="card p-lg-5 m-lg-4 m-sm-2 shadow-lg" style={{ borderRadius: '15px', backgroundColor: '', display: 'flex', flexDirection: 'column' }}>
										<div className='row py-3 px-3'>
											<div className="icon-content col-lg-12 col-md-12 col-sm-12 mb-3 float-left">
												<div className="feature-md float-left">
													<Link to="#" className="icon-cell"><img alt="" src={IconImg} /></Link>
												</div>
												<h2 className="title-head ml-3">{t(title_vision)}</h2>
											</div>
											{content2.map((item, index) => (
												<div className="col-lg-12 col-md-12 col-sm-12 float-right" key={index}>
													<h6 className="ttr-title">{t(item.Title)}</h6>
													<p>{t(item.Text)}</p>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				{/* part 3 */}
				<div className="row m-lr0">
					<div className="col-lg-12 col-md-12 p-lr30">
						<div className="heading-bx left p-r15">
							<h3 className="title-head">{t(text_title)}</h3>
						</div>
						<div class="container m-rl20">
							<div className="row m-tb20">
								{content3.map((index) => (
									<div className="col-lg-6 col-md-12 col-sm-12 m-b30">
										<div className="card shadow-lg" style={{ borderRadius: '15px', backgroundColor: '#f7b205', height: '100%', display: 'flex', padding: '20px', flexDirection: 'column' }}>
											<div className="card-body" style={{ textAlign: 'center' }}>
												<h5 className="card-title">{t(index.Text)}</h5>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div><hr />
			</>
		);
	}
}

export default FeatureContent1 = withTranslation()(FeatureContent1);