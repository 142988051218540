import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";



const services = [
	{
		id: 1,
		iconClass: "flaticon-exam",
		titleKey: "ServicesContent3_Titile4",
		link: "/news-4"
	},
	{
		id: 2,
		iconClass: "flaticon-professor",
		titleKey: "ServicesContent3_Titile0",
		link: "/news-0"
	},
	{
		id: 3,
		iconClass: "flaticon-professor",
		titleKey: "ServicesContent3_Titile1",
		link: "/news-1"
	},
	{
		id: 4,
		iconClass: "flaticon-exam",
		titleKey: "ServicesContent3_Titile2",
		link: "/news-2"
	},
	{
		id: 5,
		iconClass: "flaticon-exam",
		titleKey: "ServicesContent3_Titile3",
		link: "/news-3"
	},
];

class ServicesContent3 extends Component {
	componentDidMount() {
		
	}
	render() {
		const { t } = this.props;
		return (
			<>
				<div className="row">
					{services.map(service => (
						<div key={service.id} className="col-lg-3 col-md-6">
							<Link to={service.link}>
								<div className="services-bx text-left m-b30">
									<div className="feature-lg text-white m-b30">
										<span className="icon-cell">
											<i className={service.iconClass}></i>
										</span>
									</div>
									<div className="icon-content">
										<h4 className="ttr-tilte">{t(service.titleKey)}</h4>
									</div>
									<div className="service-no">{service.id.toString().padStart(2, '0')}</div>
								</div>
							</Link>
						</div>
					))}
				</div>
			</>
		);
	}
}

export default ServicesContent3 = withTranslation()(ServicesContent3);
