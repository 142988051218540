import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";

import pythonImg from "../../images/ioai/python.png";
import pytorchImg from "../../images/ioai/pytorch.png";
import machineLearningImg from "../../images/ioai/machine_learning.png";
import nlpImg from "../../images/ioai/nlp.png";
import wordEmbeddingsImg from "../../images/ioai/word_embeddings.png";
import transformersImg from "../../images/ioai/transformers.jpg";
import genrativeModelingImg from "../../images/ioai/generative_modeling.png";
import computerVisionImg from "../../images/ioai/computer_vision.png";
import deepLearningImg from "../../images/ioai/deep_learning.png";

import { withTranslation } from "react-i18next";

import { Card, Col, Row, Button } from 'antd';
import { Divider } from 'antd';
const { Meta } = Card;


class IOAIResource extends Component {
	componentDidMount() {
		
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />
				<div className="page-content bg-white">
					<div className="content-block" id="content-area"> 
						<div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">{t("IOAI_Resource_Title")}</h1>
								</div>
							</div>
						</div>
						<div className="breadcrumb-row">
							<div className="container">
								<ul className="list-inline">
									<li>
										<Link to="/">{t("IOAI_Resource_LinkHome")}</Link>
									</li>
									<li>{t("IOAI_Resource_LinkIOAI")}</li>
								</ul>
							</div>
						</div> 
						<div className="section-area section-sp1">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-12  heading-bx style1 text-center">
										<h2 className="title-head">{t("IOAI_Resource_Title_Sub_1")}</h2>
									</div>
									<div className="col-md-12  text-center" style={{ paddingLeft: '100px', paddingRight: '100px', }}>
										<p>
											{t("IOAI_Resource_Content_Sub_1_1")}
											<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/countries" style={{ textDecoration: "underline" }}>
												<b>
													{t("IOAI_Resource_Content_Sub_1_2")}
												</b>
											</a>
											{t("IOAI_Resource_Content_Sub_1_3")}
										</p>
									</div>
									<Divider />

									<div className="col-md-12  heading-bx style1 text-center">
										<h2 className="title-head">{t("IOAI_Resource_Title_Sub_2")}</h2>
									</div>
									<div className="col-md-12  text-center" style={{ paddingLeft: '100px', paddingRight: '100px', }}>
										<p>{t("IOAI_Resource_Content_Sub_2")}</p>
									</div>
								</div>
							</div>
						</div>

						<div style={{
							display: 'flex',
							justifyContent: 'center',
							paddingLeft: '55px',
							paddingRight: '55px',
							paddingTop: '55px',
							paddingBottom: '55px',
						}}>
							<Row gutter={[160, 0]}>
								<Col md={8} xs={24}>
									<Card
										hoverable 
										cover={<img alt="" src={pythonImg} />}
									>
										<Meta title={t("IOAI_Resource_Python_Card_Title")}
											description={t("IOAI_Resource_Python_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://colab.research.google.com/github/Nyandwi/machine_learning_complete/blob/main/0_python_for_ml/intro_to_python.ipynb#scrollTo=9-vGtxIWCOEc">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={pytorchImg} />}
									>
										<Meta title={t("IOAI_Resource_PyTorch_Card_Title")}
											description={t("IOAI_Resource_PyTorch_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://pytorch.org/tutorials/beginner/basics/intro.html">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={machineLearningImg} />}
									>
										<Meta title={t("IOAI_Resource_Machine_Learning_Card_Title")}
											description={t("IOAI_Resource_Machine_Learning_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/watch?v=jGwO_UgTS7I&list=PLoROMvodv4rMiGQp3WXShtMGgzqpfVfbU">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
							</Row>
						</div>

						<div style={{
							display: 'flex',
							justifyContent: 'center',
							paddingLeft: '55px',
							paddingRight: '55px',
							paddingTop: '55px',
							paddingBottom: '55px',
						}}>
							<Row gutter={[160, 0]}>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={nlpImg} />}
									>
										<Meta title={t("IOAI_Resource_Natural_Language_Processing_Card_Title")}
											description={t("IOAI_Resource_Natural_Language_Processing_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://web.stanford.edu/~jurafsky/slp3/">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={wordEmbeddingsImg} />}
									>
										<Meta title={t("IOAI_Resource_Word_Embeddings_Card_Title")}
											description={t("IOAI_Resource_Word_Embeddings_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://towardsdatascience.com/implementing-word2vec-in-pytorch-from-the-ground-up-c7fe5bf99889">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={transformersImg} />}
									>
										<Meta title={t("IOAI_Resource_Transformers_Card_Title")}
											description={t("IOAI_Resource_Transformers_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://uvadlc-notebooks.readthedocs.io/en/latest/tutorial_notebooks/tutorial6/Transformers_and_MHAttention.html">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
							</Row>
						</div>

						<div style={{
							display: 'flex',
							justifyContent: 'center',
							paddingLeft: '55px',
							paddingRight: '55px',
							paddingTop: '55px',
							paddingBottom: '55px',
						}}>
							<Row gutter={[160, 0]}>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={genrativeModelingImg} />}
									>
										<Meta title={t("IOAI_Resource_Generative_Modeling_Card_Title")}
											description={t("IOAI_Resource_Generative_Modeling_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://github.com/karpathy/minGPT">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={computerVisionImg} />}
									>
										<Meta title={t("IOAI_Resource_Computer_Vision_Card_Title")}
											description={t("IOAI_Resource_Computer_Vision_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://cs231n.stanford.edu/">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
								<Col md={8} xs={24}>
									<Card
										hoverable
										cover={<img alt="" src={deepLearningImg} />}
									>
										<Meta title={t("IOAI_Resource_Deep_Learning_Card_Title")}
											description={t("IOAI_Resource_Deep_Learning_Card_Description")} />
										<div style={{ marginTop: 16 }}>
											<div style={{ float: 'right', marginBottom: 8 }}>
												<a rel="noopener noreferrer" target="_blank" href="https://www.coursera.org/specializations/deep-learning">
													<Button type="primary">Learn More</Button>
												</a>
											</div>
											<div style={{ clear: 'both' }} />
										</div>
									</Card>
								</Col>
							</Row>
						</div>

						<Divider />
					</div>
				</div >
			</>
		);
	}
}

export default IOAIResource = withTranslation()(IOAIResource);
