import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import news01Img from "../../images/news/news0_1.png";
import news02Img from "../../images/news/news0_2.png";
import news03Img from "../../images/news/news0_3.png";
import news04Img from "../../images/news/news0_4.png";
import news05Img from "../../images/news/news0_5.png";
import news06Img from "../../images/news/news0_6.png";

import { withTranslation } from "react-i18next";


class News0 extends Component {
	componentDidMount() {
		
	}

	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News0_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News0_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News0_LinkHome")}</Link>
								</li>
								<li>{t("News0_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<div id="img-content" className="rich_media_wrp">
												<h1 className="rich_media_title" id="activity-name">
													{t("News0_Title")}
												</h1>
												<div id="meta_content" className="rich_media_meta_list">
													<span className="rich_media_meta rich_media_meta_text">
														Leading Coding
													</span>
													<span className="rich_media_meta rich_media_meta_nickname" id="profileBt">
														<div id="js_profile_qrcode" aria-hidden="true" className="profile_container" style={{ display: 'none' }}>
															<div className="profile_inner">
																<strong className="profile_nickname">Leading Coding</strong>
																<img alt="" src={news01Img} width="80%" />

															</div>
															<span className="profile_arrow_wrp" id="js_profile_arrow_wrp">
																<i className="profile_arrow arrow_out"></i>
																<i className="profile_arrow arrow_in"></i>
															</span>
														</div>
													</span>
													<span id="meta_content_hide_info">
														<em> 2024-06-06 21:00 </em>
														<em style={{ display: 'inline-block' }}>
															<span aria-hidden="true">{t("News0_Title_0")}</span>
														</em>
													</span>
												</div>
												<div>
													<br />
													<h6>{t("News0_Title_1")}</h6>
													<br /><br />
													<img alt="" src={news01Img} width="90%" />
													<br /><br />
													<p>
														<strong>
															<span style={{ color: 'rgb(61, 170, 214)', visibility: 'visible' }}>{t("News0_Content_P1")}</span>
														</strong>
														{t("News0_Content_P2")}
														<br />
														<img alt="" src={news02Img} width="70%" />
													</p>
													<br />

													<h6>{t("News0_Title_2")}</h6>
													<br />

													{t("News0_Title_3")}
													<strong>
														<span style={{ color: 'rgb(61, 170, 214)', visibility: 'visible' }}>{t("News0_Content_P3")}</span>
													</strong>
													{t("News0_Content_P4")}
													<strong>
														<span style={{ color: 'rgb(61, 170, 214)' }}>{t("News0_Content_P5")}</span>
													</strong>
													{t("News0_Content_P6")}
													<strong>
														<span style={{ color: 'rgb(61, 170, 214)' }}>{t("News0_Content_P6_1")}</span>
													</strong>
													<br /><br />
													<span>{t("News0_Content_P7")}</span>
													<p>
														<br /><img alt="" src={news03Img} width="70%" />
													</p>
													<br /><h6>{t("News0_Title_4")}</h6><br />
													<p>
														{t("News0_Content_P8")}
														<span style={{ color: 'rgb(61, 170, 214)' }}>
															<strong>{t("News0_Content_P9")}</strong>
														</span>
													</p>
													<p>
														{t("News0_Content_P10")}
														<span style={{ color: 'rgb(61, 170, 214)' }}>
															<strong>{t("News0_Content_P11")}</strong>
														</span>
													</p>
													<p>
														{t("News0_Content_P12")}
														<span style={{ color: 'rgb(61, 170, 214)' }}>
															<strong>{t("News0_Content_P13")}</strong>
														</span>
													</p>
													<p>
														<img alt="" src={news04Img} width="70%" />													</p>
													<br /><h6>{t("News0_Title_5")}</h6><br />
													<p>
														<span>
															<span>{t("News0_Content_P14")}</span>
														</span>
														<span style={{ color: 'rgb(61, 170, 214)' }}>
															<strong>{t("News0_Content_P15")}</strong>
														</span>
													</p>
													<p>
														<img alt="" src={news05Img} width="60%" />
													</p>
													<br /><h6>{t("News0_Title_6")}</h6><br />
													<p>
														{t("News0_Content_P16")}
														<strong>
															<span style={{ color: 'rgb(61, 170, 214)' }}>{t("News0_Content_P17")}</span>
														</strong>
													</p>
													<p>
														{t("News0_Content_P18")}
														<span style={{ color: 'rgb(61, 170, 214)' }}>
															<strong>{t("News0_Content_P19")}</strong>
														</span>
													</p>
													<p>
														<span style={{ textDecoration: 'underline', color: 'black' }}>
															<strong>{t("News0_Content_P20")}</strong>
														</span>
													</p>
													<p>
														<span style={{ textDecoration: 'underline', color: 'black' }}>
															<strong>{t("News0_Content_P21")}&nbsp;</strong>
														</span>
													</p>
													<p>
														<img alt="" src={news06Img} width="80%" />													</p>
													<p style={{ marginBottom: 0, outline: 0 }}>
														<span style={{ fontSize: '15px' }}>{t("News0_Content_P22")}<br /></span>
													</p>
													<br />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default News0 = withTranslation()(News0);
