/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";



function VideoContent() {
	return (
		<>
		{/* <div className="container">
		<div className="row">
		  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
			<iframe
			  width="320"
			  height="200"
			  src="https://www.youtube.com/embed/ijKkV-OGrJU"
			  title="Hungry Snake"
			  frameBorder="0"
			  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			  allowFullScreen
			></iframe>
		  </div>
		  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
			<iframe
			  width="320"
			  height="200"
			  src="https://www.youtube.com/embed/21pxZ-0Pmj4"
			  title="Video 2"
			  frameBorder="0"
			  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			  allowFullScreen
			></iframe>
		  </div>
		  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
			<iframe
			  width="320"
			  height="200"
			  src="https://www.youtube.com/embed/jAq2u-8Iy1A"
			  title="Video 3"
			  frameBorder="0"
			  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			  allowFullScreen
			></iframe>
		  </div>
		</div>
	  </div> */}
		</>
	);
}

class CourseVideoContent extends Component {
	componentDidMount() {
		
	}
	render() {
		return (
			<>
				<VideoContent />
			</>
		);
	}
}

export default withTranslation()(CourseVideoContent);
