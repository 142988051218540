
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header/header1";
import bannerImg from "../../images/banner/banner2.jpg";
import { withTranslation } from "react-i18next";
import peopleImg from "../../images/ioai/people.png";
import i18next from "i18next";

const scientificCommittee = [
    {
        role: "IOAI_Team_Chair_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_1",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_1_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_1_Title_2",
            "IOAI_Team_Scientific_Commitee_Name_1_Title_3"
        ]
    },
    {
        role: "IOAI_Team_Advisor_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_2",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_2_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_2_Title_2",
            "IOAI_Team_Scientific_Commitee_Name_2_Title_3"
        ]
    },
    {
        role: "IOAI_Team_Member_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_3",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_3_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_3_Title_2"
        ]
    },
    {
        role: "IOAI_Team_Member_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_4",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_4_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_4_Title_2",
            "IOAI_Team_Scientific_Commitee_Name_4_Title_3"
        ]
    },
    {
        role: "IOAI_Team_Member_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_5",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_5_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_5_Title_2"
        ] 
    },
    {
        role: "IOAI_Team_Member_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_6",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_6_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_6_Title_2"
        ]
    }
];
//organizer list
const Organizers = [
    {
        role: "IOAI_Team_Chair_Title",
        name: "IOAI_Team_Organizer_Name_1",
        titles: [
            "IOAI_Team_Organizer_Name_1_Title_1",
            "IOAI_Team_Organizer_Name_1_Title_2",
            "IOAI_Team_Organizer_Name_1_Title_3"
        ]
    },
    {
        role: "IOAI_Team_Advisor_Title",
        name: "IOAI_Team_Organizer_Name_2",
        titles: [
            "IOAI_Team_Organizer_Name_2_Title_1",
            "IOAI_Team_Organizer_Name_2_Title_2",
            "IOAI_Team_Organizer_Name_2_Title_3"
        ]
    },
    {
        role: "IOAI_Team_Member_Title",
        name: "IOAI_Team_Organizer_Name_3",
        titles: [
            "IOAI_Team_Organizer_Name_3_Title_1",
            "IOAI_Team_Organizer_Name_3_Title_2"
        ]
    },
    // {
    //     role: "IOAI_Team_Member_Title"),
    //     name: "IOAI_Team_Organizer_Name_4"),
    //     titles: [
    //         "IOAI_Team_Organizer_Name_4_Title_1"),
    //         "IOAI_Team_Organizer_Name_4_Title_2"),
    //         "IOAI_Team_Organizer_Name_4_Title_3"),
    //         "IOAI_Team_Organizer_Name_4_Title_4")
    //     ]
    // },
    {
        role: "IOAI_Team_Member_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_6",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_6_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_6_Title_2"
        ]
    },
    {
        role: "IOAI_Team_Chair_Title",
        name: "IOAI_Team_Scientific_Commitee_Name_1",
        titles: [
            "IOAI_Team_Scientific_Commitee_Name_1_Title_1",
            "IOAI_Team_Scientific_Commitee_Name_1_Title_2",
            "IOAI_Team_Scientific_Commitee_Name_1_Title_3"
        ]
    },
];


const ScientificCommitteeMember = ({ role, name, titles }) => (
    <li className="p-4 col-lg-6 col-md-12 col-sm-12 mb-4 text-center">
        <h1 style={{ fontFamily: 'Garamond, serif', color: 'navy' }}>{role}</h1>
        <h5>{name}</h5>
        <hr />
        <ul className="list-unstyled">
            {titles.map((title, index) => (
                <li key={index} className="p-1">{title}</li>
            ))}
        </ul>
    </li> 
);

const Organizer = ({ role, name, titles }) => (
    <li className="p-4 col-lg-6 col-md-12 col-sm-12 mb-4 text-center">
        <h1 style={{ fontFamily: 'Garamond, serif', color: 'navy' }}>{role}</h1>
        <h5>{name}</h5>
        <hr />
        <ul className="list-unstyled">
            {titles.map((title, index) => (
                <li key={index} className="p-1">{title}</li>
            ))}
        </ul>
    </li>
);
class AOAITeam extends Component {
    componentDidMount() {
        
    }
    
    render() {
        const { t } = this.props;

        return (
            <>
                <Header />
                <div className="page-content">
                    <div className="content-block" id="content-area">
                        <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
                            <div className="container">
                                <div className="page-banner-entry">
                                    <h1 className="text-white">{t("IOAI_Team_Banner_Title")}</h1>
                                </div>
                            </div>
                        </div> 
                        <div className="breadcrumb-row">
                            <div className="container">
                                <ul className="list-inline">
                                    <li>
                                        <Link to="/">{t("IOAI_Application_LinkHome")}</Link>
                                    </li>
                                    <li>{t("IOAI_Team_Link")}</li>
                                </ul>
                            </div>
                        </div>
                        {/* Team Section */}
                        <div className="content-block m-tb50" id="content-area"> 
                            <div className="container">
                                <h1 className="text-center">{t("IOAI_Team_Title")}</h1>
                                {/* section card: scientific commitee */}
                                <div className="container mt-4">
                                    <div className="row">
                                        <div className=" mb-4">
                                            <div className="card" style={{ borderRadius: '15px', backgroundColor: '#eaeff6', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <div className="card-body" >
                                                    <img alt="" src={peopleImg} style={{ maxWidth: '10%', height: 'auto', textAlign: 'left' }} />
                                                    <h5 className="card-Title">{t("IOAI_Card_Team_Scientifc_Title")}</h5>
                                                    <p className="card-text">
                                                        {t("IOAI_Card_Team_Scientific_Content")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section of people of scientific commitee */}
                                    <div className="content-block m-tb50" id="content-area">
                                        <div className="container">
                                            <h1 className="text-center">{t("IOAI_Card_Team_Scientifc_Title")}</h1>
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-unstyled">
                                                        <div className="row">
                                                            {scientificCommittee.slice(0, 2).map((member, index) => (
                                                                <ScientificCommitteeMember 
                                                                    key={index}
                                                                    role={i18next.t(member.role)}
                                                                    name={i18next.t(member.name)}
                                                                    titles={member.titles.map(title => i18next.t(title))}                                                                />
                                                            ))}
                                                        </div>
                                                        <h1 style={{ textAlign: 'center', fontFamily: 'Garamond, serif', color: 'navy' }}>{t("IOAI_Team_Member_Title")}</h1>
                                                        <div className="row">
                                                            {scientificCommittee.slice(2).map((member, index) => (
                                                                <ScientificCommitteeMember 
                                                                    key={index}
                                                                    name={i18next.t(member.name)}
                                                                    titles={member.titles.map(title => i18next.t(title))}
                                                                />
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end of scientific commitee section */}
                                </div>
                                {/* section for card: organizers */}
                                <div className="container-fluid mt-4">
                                    <div className="row">
                                        <div className=" mb-4">
                                            <div className="card" style={{ borderRadius: '15px', backgroundColor: '#eaeff6', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <div className="card-body">
                                                    <img alt="" src={peopleImg} style={{ maxWidth: '10%', height: 'auto', textAlign: 'left' }} />
                                                    <h5 className="card-Title">{t("IOAI_Card_Team_Organizer_Title")}</h5>
                                                    <p className="card-text">
                                                        {t("IOAI_Card_Team_Organizer_Content")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Organizer Section */}
                                    <div className="content-block m-tb50" id="content-area">
                                        <div className="container">
                                            <h1 className="text-center">{t("IOAI_Card_Team_Organizer_Title")}</h1>
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="list-unstyled">
                                                        <div className="row">
                                                            {Organizers.slice(0, 2).map((member, index) => (
                                                                <Organizer 
                                                                    key={index}
                                                                    role={i18next.t(member.role)}
                                                                    name={i18next.t(member.name)}
                                                                    titles={member.titles.map(title => i18next.t(title))}
                                                                />
                                                            ))}
                                                        </div>
                                                        <h1 style={{ textAlign: 'center', fontFamily: 'Garamond, serif', color: 'navy' }}>{t("IOAI_Team_Member_Title")}</h1>
                                                        <div className="row">
                                                            {Organizers.slice(2).map((member, index) => (
                                                                <Organizer 
                                                                    key={index}
                                                                    name={i18next.t(member.name)}
                                                                    titles={member.titles.map(title => i18next.t(title))}
                                        
                                                                />
                                                            ))}
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end of organizers section */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withTranslation()(AOAITeam);