import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header1 from "../layout/header/header1";

// Elements
import FeatureContent1 from "../elements/feature-content1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import { withTranslation } from "react-i18next";

	
class About1 extends Component {
	componentDidMount() {
		
	}
	render() {
		const { t } = this.props;
		return (
			<>
				<Header1 />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("About_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("About_LinkHome")}</Link>
								</li>
								<li>{t("About_LinkAbout")}</li>
							</ul>
						</div>
					</div>

					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<FeatureContent1 />
							</div>
						</div>

					
					</div>
				</div>
			</>
		);
	}
}

export default About1 = withTranslation()(About1);
