import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header/header1";

// Elements
import PortfolioContent from "../elements/portfolio-content";

// Images
import bannerImg from '../../images/banner/banner1.jpg';
import { withTranslation } from 'react-i18next';

	
class Portfolio extends Component{
	componentDidMount() {
		
	}
	render()
	{
		const { t } = this.props;
		return (
			<>
				
				<Header />
				
				<div className="page-content">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bannerImg+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{ t('Portf_Title') }</h1>
							 </div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li><Link to="/">{ t('Portf_LinkHome') }</Link></li>
								<li>{ t('Portf_LinkPortf') }</li>
							</ul>
						</div>
					</div>
					
					<div className="content-block">
						<div className="section-area section-sp1 gallery-bx">
							<div className="container">
								<PortfolioContent />
							</div>
						</div> 
					</div> 
				</div>
			</>
		);
	}
}

export default Portfolio =  withTranslation()(Portfolio);