import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner2.jpg";

import { withTranslation } from "react-i18next";


import { Card, Col, Row } from 'antd';
import { Divider } from 'antd';

import countriesImg from '../../images/ioai/countries.png';
import ioaiImg from '../../images/ioai/ioai.png';
import round1Img from '../../images/ioai/round1.png';
import round2Img from '../../images/ioai/round2.png';

class IOAIIntroduction extends Component {
	componentDidMount() {
		
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />
				<div className="page-content bg-white">
					<div className="content-block" id="content-area"> 
						<div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">{t("IOAI_Introduction_Title")}</h1>
								</div>
							</div>
						</div>
						<div className="breadcrumb-row">
							<div className="container">
								<ul className="list-inline">
									<li>
										<Link to="/">{t("IOAI_Introduction_LinkHome")}</Link>
									</li>
									<li>{t("IOAI_Introduction_LinkIOAI")}</li>
								</ul>
							</div>
						</div>
						<div className="section-area section-sp1">
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-12 heading-bx style1 text-center">
										<h2 className="title-head">{t("IOAI_About_Card_1_Title")}</h2>
									</div>
									<div className="col-md-6 text-center">
										<img alt="" src={countriesImg} style={{ maxWidth: '80%', height: 'auto' }} />
									</div>
									<div className="col-md-6 text-center">
										<img alt="" src={ioaiImg} style={{ maxWidth: '80%', height: 'auto' }} />
									</div>
									<div className="col-md-12 text-left" style={{ paddingLeft: '100px', paddingRight: '100px', }}>
										<p>
											<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/countries" style={{ textDecoration: "underline" }}>
												<b>
													{t("IOAI_About_Card_1_Content_1")}
												</b>
											</a>
											{t("IOAI_About_Card_1_Content_2")}
											<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/countries" style={{ textDecoration: "underline" }}>
												<b>
													{t("IOAI_About_Card_1_Content_3")}
												</b>
											</a>
											.
										</p>
									</div>
									<Divider />
								</div>
							</div>
						</div>
						<Row justify="center" gutter={[16, 16]} style={{ marginBottom: '40px' }}>
							<Col xs={24} sm={24} md={20}>
								<Card title={<h2 style={{ fontWeight: 'bold', marginBottom: 30, textAlign: 'center' }}>{t("IOAI_About_Card_2_Title")}</h2>} bordered={false}>
									<p style={{ fontSize: '16px', lineHeight: '1.75', textAlign: 'left' }}>
										{t("IOAI_About_Card_2_Content_Above")} <span style={{ fontWeight: "bold" }}>{t("IOAI_About_Card_2_sub1_Title")}</span> {t("IOAI_About_Card_2_sub2_Title")} <span style={{ fontWeight: "bold" }}>{t("IOAI_About_Card_2_sub3_Title")}</span> {t("IOAI_About_Card_2_Content_Below")}
									</p>
									<Row justify="center" gutter={[16, 16]}>
										<Col xs={24} sm={24} md={12}>
											<Card type="inner" title={<h5 style={{ marginTop: 20 }}>{t("IOAI_About_Card_2_InnerCard_1_Title")}</h5>} extra={<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/what-is-ioai/">More</a>} style={{ marginBottom: '30px', marginTop: '30px' }}>
												<p style={{ fontSize: '16px', lineHeight: '1.6' }}>
													{t("IOAI_About_Card_2_InnerCard_1_Content")}
												</p>
											</Card>
										</Col>
										<Col xs={24} sm={24} md={12}>
											<div style={{ maxHeight: '300px', overflow: 'auto', textAlign: 'center' }}>
												<img alt="" src={round1Img} style={{ maxWidth: '80%', height: 'auto' }} />
											</div>
										</Col>
									</Row>
									<Row justify="center" gutter={[16, 16]}>
										<Col xs={24} sm={24} md={12}>
											<Card style={{ marginTop: 16 }} type="inner" title={<h5>{t("IOAI_About_Card_2_InnerCard_2_Title")}</h5>} extra={<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/what-is-ioai/">More</a>}>
												<p style={{ fontSize: '16px', lineHeight: '1.6' }}>
													{t("IOAI_About_Card_2_InnerCard_2_Content")}
												</p>
											</Card>
										</Col>
										<Col xs={24} sm={24} md={12}>
											<div style={{ maxHeight: '300px', overflow: 'auto', textAlign: 'center' }}>
												<img alt="" src={round2Img} style={{ maxWidth: '80%', height: 'auto' }} />
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
						<Row justify="center" gutter={[16, 16]} style={{ marginBottom: '40px' }}>
							<Col xs={24} sm={24} md={10}>
								<Card title={<h2 style={{ fontWeight: 'bold', marginBottom: 30 }}>{t("IOAI_About_Card_3_Title")}</h2>} bordered={false}>
									<p style={{ fontSize: '16px', lineHeight: '1.75' }}>
										{t("IOAI_About_Card_3_Content")}
									</p>
									<Card type="inner" title={<h5 style={{ marginTop: 20 }}>{t("IOAI_About_Card_3_InnerCard_1_Title")}</h5>} style={{ marginBottom: '30px', marginTop: '30px' }}>
										<ol style={{ paddingLeft: 20, fontSize: 16, lineHeight: '1.6' }}>
											<li style={{ marginBottom: 12 }}>
												<a rel="noopener noreferrer" style={{ color: "#1890ff", textDecoration: "none", display: "block", marginBottom: 8 }} target="_blank" href="https://colab.research.google.com/drive/1DOY-pEH5nr7X6Tb9x58seOOWGuybFwCb">{t("IOAI_About_Card_3_Scientific_Link1")}</a>
											</li>
											<li style={{ marginBottom: 12 }}>
												<a rel="noopener noreferrer" style={{ color: "#1890ff", textDecoration: "none", display: "block", marginBottom: 8 }} target="_blank" href="https://colab.research.google.com/drive/16G0GBBwEqot0xoDBV30ftguN9yWCyzk_?usp=sharing">{t("IOAI_About_Card_3_Scientific_Link2")}</a>
											</li>
											<li style={{ marginBottom: 12 }}>
												<a rel="noopener noreferrer" style={{ color: "#1890ff", textDecoration: "none", display: "block", marginBottom: 8 }} target="_blank" href="https://colab.research.google.com/drive/1BsvLtgho7SnZ93hyH22dxGpwSKHefDib?usp=sharing">{t("IOAI_About_Card_3_Scientific_Link3")}</a>
											</li>
										</ol>
									</Card>
									<Card type="inner" title={<h5>{t("IOAI_About_Card_3_InnerCard_2_Title")}</h5>}>
										<ol style={{ paddingLeft: 20, fontSize: 16, lineHeight: '1.6' }}>
											<li style={{ marginBottom: 12 }}>
												<a rel="noopener noreferrer" style={{ color: "#1890ff", textDecoration: "none", display: "block", marginBottom: 8 }} target="_blank" href="https://docs.google.com/document/d/1wMQuBPn6cxbzz9h-4YxIUZlG9wvLrErBlPYDI2xnHtc/edit#heading=h.3iy9ofk805eb">{t("IOAI_About_Card_3_Practical_Link1")}</a>
											</li>
										</ol>
									</Card>
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(IOAIIntroduction);
