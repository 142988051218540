import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import news41Img from "../../images/news/news4_1.jpg";
import news42Img from "../../images/news/news4_2.jpg";
import news43Img from "../../images/news/news4_3.jpg";
import news44Img from "../../images/news/news4_4.jpg";
import news45Img from "../../images/news/news4_5.jpg";
import news46Img from "../../images/news/news4_6.jpg";
import news47Img from "../../images/news/news4_7.jpg";
import news48Img from "../../images/news/news4_8.jpg";
import news49Img from "../../images/news/news4_9.jpg";
import news40Img from "../../images/news/news4_10.jpg";
import news412Img from "../../images/news/news4_12.jpg";

import { withTranslation } from "react-i18next";

	
class News4 extends Component {
	componentDidMount() {
		
	}

	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News4_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News4_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News4_LinkHome")}</Link>
								</li>
								<li>{t("News4_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("News4_Content_H")}</h1>
											{/* <p>{t("News3_Content_Start")}<u><a rel="noopener noreferrer" href="https://mp.weixin.qq.com/s/IyF5uTa2fHDD7idQN-rDgg" target="_blank">{t("News3_Content_Link")}</a></u>{t("News3_Content_End")}</p> */}
											<p>{t("News4_Content_P1")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news41Img} /></p>
											<p>{t("News4_Content_P2")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news42Img} /></p>
											<p>{t("News4_Content_P3")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news43Img} /></p>
											<p>{t("News4_Content_P4")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news44Img} /></p>
											<p style={{textAlign: "center"}}><img alt="" src={news45Img} /></p>
											<p>{t("News4_Content_P5")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news46Img} /></p>
											<p style={{textAlign: "center"}}><img alt="" src={news47Img} /></p>
											<p>{t("News4_Content_P6")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news48Img} /></p>
											<p style={{textAlign: "center"}}><img alt="" src={news49Img} /></p>
											<p>{t("News4_Content_P7")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news40Img} /></p>
											<p>{t("News4_Content_P8")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news412Img} /></p>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default News4 = withTranslation()(News4);
