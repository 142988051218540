import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
import logo from "../../../images/logo.png";
import salogo from "../../../images/salogo.png";

import i18n from "i18next";
import { withTranslation } from "react-i18next";




class Header1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedLang: i18n.language,
		};
	}

	componentDidMount() {
		

		// 初始化语言选项
		this.setState({ selectedLang: i18n.language });

		// 监听语言选项的变化并更新选项的值
		i18n.on("languageChanged", (lang) => {
			this.setState({ selectedLang: lang });
		});

		// Mobile Menu sidebar function
		var btn = document.querySelector(".menuicon");
		var nav = document.querySelector(".menu-links");

		function toggleFunc() {
			btn.classList.toggle("open");
			nav.classList.toggle("show");
		}

		btn.addEventListener("click", toggleFunc);

		// Mobile Submenu open close function
		var navMenu = [].slice.call(
			document.querySelectorAll(".menu-links > ul > li")
		);
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener("click", function () {
				menuClick(this);
			});
		}

		function menuClick(current) {
			const active = current.classList.contains("open");
			navMenu.forEach((el) => el.classList.remove("open"));

			if (active) {
				current.classList.remove("open");
			} else {
				current.classList.add("open");
			}
		}
	}

	componentWillUnmount() {
		// 组件卸载时清除事件
		i18n.off("languageChanged");
	}




	render() {
		const { t } = this.props;
		const { selectedLang } = this.state;

		const changeLanguage = (val) => {
			i18n.changeLanguage(val.target.value);
		};

		return (
			<>
				<header className="header rs-nav">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									<ul>
										<li>
											<a rel="noopener" href="/contact">
												<i className="fa fa-question-circle"></i>
												{t("Header_AskQues")}
											</a>
										</li>
										<li>
											<a rel="noopener" href="mailto:admin@leadingcoding.com.au" style={{ textDecoration: "underline" }}>
												<i className="fa fa-envelope-o"></i>
												admin@leadingcoding.com.au
											</a>
										</li>
										<li>
											<a rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=61550979300196" target="_blank">
												<i className="fa fa-facebook-official"></i>
												Leading Coding
											</a>
										</li>
										<li>
											<a rel="noopener noreferrer" href="https://www.linkedin.com/company/leading-coding/" target="_blank">
												<i className="fa fa-linkedin"></i>
												Leading Coding
											</a>
										</li>
									</ul>
								</div>
								<div className="topbar-right">
									<ul>
										<li>
											<select
												className="header-lang-bx"
												value={selectedLang}
												onChange={changeLanguage}
											>
												<option data-icon="flag flag-uk" value="en">
													English
												</option>
												<option data-icon="flag flag-us" value="zh">
													中文
												</option>
											</select>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header SALogo ==== --> */}
								<div className="menu-salogo">
									<Link to="/">
										<img alt="" src={salogo} />
									</Link>
								</div>

								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to="/">
										<img alt="" src={logo} />
									</Link>
								</div>

								{/* <!-- Mobile Nav Button ==== --> */}
								<button
									className="navbar-toggler collapsed menuicon justify-content-end"
									type="button"
									data-toggle="collapse"
									data-target="#menuDropdown"
									aria-controls="menuDropdown"
									aria-expanded="false"
									aria-label="Toggle navigation"
								>
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Navigation Menu ==== --> */}
								<div
									className="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown"
								>
									<div className="menu-logo">
										<Link to="/">
											<img alt="" src={logo} />
										</Link>
									</div>
									<ul className="nav navbar-nav">
										<li>
											<Link to="/">{t("Header_Home")}</Link>
										</li>
										<li className="active">
											<Link to="#">{t("Header_IOAI")} <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li>
													<Link to="/ioai-introduction">
														{t("Header_IOAI_Introduction")}
													</Link>
												</li>
												<li>
													<Link to="/ioai-resource">
														{t("Header_IOAI_Resource")}
													</Link>
												</li>
												<li>
													<Link to="/ioai-sponsor">
														{t("Header_IOAI_Sponsor")}
													</Link>
												</li>

												<li className="active open">
													<Link to="#">{t("IOAI_2024")} <i className="fa fa-chevron-right"></i></Link>
													<ul className="sub-menu">
														<li>
															<Link to="/aoai-team">
																{t("Header_IOAI_Team")}
															</Link>
														</li>
														<li>
															<Link to="/ioai-application">
																{t("Header_IOAI_Application")}
															</Link>
														</li>
														<li>
															<Link to="/ioai-application-journey">{t("ioai-application-joureny")}</Link>
														</li>
													</ul>
												</li>
											</ul>
										</li>
										<li>
											<Link to="/courses-details">
												{t("Header_CoursesDetail")}
											</Link>
										</li>
										<li>
											<Link to="/portfolio">{t("Header_Profile")}</Link>
										</li>
										<li>
											<Link to="/video">{t("Header_Video")}</Link>
										</li>
										<li>
											<Link to="/about">{t("Header_About")}</Link>
										</li>
										<li>
											<Link to="/contact">{t("Header_Contact")}</Link>
										</li>
									</ul>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input
								name="search"
								type="text"
								className="form-control"
								placeholder="Type to search"
							/>
							<span>
								<i className="ti-search"></i>
							</span>
						</form>
						<span id="search-remove">
							<i className="ti-close"></i>
						</span>
					</div>
				</header>
			</>
		);
	}
}

export default Header1 = withTranslation()(Header1);
